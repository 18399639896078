import React, { useEffect, useState } from 'react';
import apiClient from '../apiClient';
import { useNavigate } from 'react-router-dom';

const QuestionForm = () => {
  const [questionText, setQuestionText] = useState('');
  const [answers, setAnswers] = useState([
    { answerText: '', correct: false },
    { answerText: '', correct: false },
    { answerText: '', correct: false },
  ]);
  const [imageUrl, setImageUrl] = useState('');
  const [country, setCountry] = useState('');
  const [permission, setPermission] = useState('');
  const [temary, setTemary] = useState('');
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [temaries, setTemaries] = useState([]);
  const countriesApiUrl = process.env.REACT_APP_GET_PAISES_URL;
  const permissionsApiUrl = process.env.REACT_APP_GET_TIPOS_CARNET_URL;
  const temariesApiUrl = process.env.REACT_APP_GET_TEMAS_URL;
  const navigate = useNavigate();

  // Fetch countries from the API
  const fetchCountries = async () => {
    try {
      const response = await apiClient(countriesApiUrl);
      setCountries(response.data);
    } catch (error) {
      console.error('Error al cargar los países:', error);
    }
  };

  // Fetch permissions based on country from the API
  const fetchPermissions = async (countryId) => {
    if (!countryId) return;

    try {
      const response = await apiClient(`${permissionsApiUrl}/${countryId}`);
      setPermissions(response.data);
    } catch (error) {
      console.error('Error al cargar los permisos:', error);
    }
  };

  // Fetch temaries based on permission from the API
  const fetchTemaries = async (countryId, permissionId) => {
    if (!countryId || !permissionId) return;

    try {
      const response = await apiClient(`${temariesApiUrl}?paisId=${countryId}&carnetId=${permissionId}`);
      setTemaries(response.data);
    } catch (error) {
      console.error('Error al cargar los temarios:', error);
    }
  };

  // Fetch countries on component mount
  useEffect(() => {
    fetchCountries();
  }, []);

  // Fetch permissions when country changes
  useEffect(() => {
    if (country) {
      fetchPermissions(country);
      setPermissions([]);
      setTemaries([]);
      setPermission('');
      setTemary('');
    }
  }, [country]);

  // Fetch temaries when permission changes
  useEffect(() => {
    if (country && permission) {
      fetchTemaries(country, permission);
      setTemary('');
    } else {
      setTemaries([]);
    }
  }, [country, permission]);

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index].answerText = value;
    setAnswers(updatedAnswers);
  };

  const handleCorrectChange = (index) => {
    const updatedAnswers = answers.map((answer, i) => ({
      ...answer,
      correct: i === index,
    }));
    setAnswers(updatedAnswers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !questionText ||
      !country ||
      !permission ||
      !temary ||
      answers.some((answer) => !answer.answerText)
    ) {
      alert('Por favor completa todos los campos.');
      return;
    }

    setLoading(true);

    const questionData = {
      questionText,
      answers,
      imageUrl,
      country,
      permission,
      temary,
    };

    // Build the API URL dynamically to include the country, permission, and temary
    const apiUrl = `${process.env.REACT_APP_CREATE_QUESTION_URL}/${country}/${permission}/${temary}/preguntas`;

    try {
      await apiClient(apiUrl, {
        method: 'POST',
        data: questionData,
      });

      navigate('/questions');
      resetForm();
    } catch (error) {
      console.error('Error de conexión:', error);
      alert('Error al guardar la pregunta.');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setQuestionText('');
    setAnswers([
      { answerText: '', correct: false },
      { answerText: '', correct: false },
      { answerText: '', correct: false },
    ]);
    setImageUrl('');
    setCountry('');
    setPermission('');
    setTemary('');
    setPermissions([]);
    setTemaries([]);
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold text-center mb-4">Agregar Pregunta</h2>

      <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-4 shadow-md rounded-md">
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Texto de la pregunta</label>
          <input
            type="text"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Escribe la pregunta"
          />
        </div>

        {answers.map((answer, index) => (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              Respuesta {index + 1}
            </label>
            <input
              type="text"
              value={answer.answerText}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder={`Respuesta ${index + 1}`}
            />
            <div className="mt-2">
              <label>
                <input
                  type="radio"
                  name="correctAnswer"
                  checked={answer.correct}
                  onChange={() => handleCorrectChange(index)}
                  className="mr-2"
                />
                Marcar como correcta
              </label>
            </div>
          </div>
        ))}

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">URL de la imagen</label>
          <input
            type="text"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Ingresa la URL de la imagen"
          />
        </div>

        {/* Filtro por País */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">País</label>
          <select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
          >
            <option value="">Selecciona un país</option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>
                {country.nombre}
              </option>
            ))}
          </select>
        </div>

        {/* Filtro por Tipo de Carnet */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Tipo de Carnet</label>
          <select
            value={permission}
            onChange={(e) => setPermission(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
            disabled={!country}
          >
            <option value="">Selecciona un tipo de carnet</option>
            {permissions.map((permission) => (
              <option key={permission.id} value={permission.id}>
                {permission.nombre}
              </option>
            ))}
          </select>
        </div>

        {/* Filtro por Temario */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Temario</label>
          <select
            value={temary}
            onChange={(e) => setTemary(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
            disabled={!permission}
          >
            <option value="">Selecciona un temario</option>
            {temaries.map((temary) => (
              <option key={temary.id} value={temary.id}>
                {temary.nombre}
              </option>
            ))}
          </select>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
          disabled={loading}
        >
          {loading ? 'Guardando...' : 'Guardar Pregunta'}
        </button>
      </form>
    </div>
  );
};

export default QuestionForm;
