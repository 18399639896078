import React, { useEffect, useState } from 'react';
import apiClient from './apiClient'; // Importar apiClient para llamadas a la API
import { useNavigate } from 'react-router-dom';

const GestorTemasPreguntas = () => {
  const [paises, setPaises] = useState([]);
  const [tiposDeCarnet, setTiposDeCarnet] = useState([]);
  const [temas, setTemas] = useState([]);
  const [preguntas, setPreguntas] = useState([]);
  const [selectedPais, setSelectedPais] = useState('');
  const [selectedCarnet, setSelectedCarnet] = useState('');
  const [selectedTemario, setSelectedTemario] = useState('');
  const [nuevoTema, setNuevoTema] = useState({ nombre: '', descripcion: '', numero: '' });
  const [questionText, setQuestionText] = useState('');
  const [answers, setAnswers] = useState([
    { answerText: '', correct: false },
    { answerText: '', correct: false },
    { answerText: '', correct: false },
  ]);
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [lastVisibleId, setLastVisibleId] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const navigate = useNavigate();

  // URLs de las API (usar variables de entorno o URLs configuradas)
  const getPaisesUrl = process.env.REACT_APP_GET_PAISES_URL;
  const getTiposDeCarnetUrl = process.env.REACT_APP_GET_TIPOS_CARNET_URL;
  const getTemasUrl = process.env.REACT_APP_GET_TEMAS_URL;
  const getPreguntasUrl = process.env.REACT_APP_GET_QUESTIONS_URL;
  const addPreguntaUrl = process.env.REACT_APP_CREATE_QUESTION_URL;

  // Límite para paginación
  const limit = 10;

  // Cargar países desde la API
  const fetchPaises = async () => {
    setLoading(true);
    try {
      const response = await apiClient(getPaisesUrl);
      setPaises(response.data);
    } catch (error) {
      console.error('Error al cargar los países:', error);
    } finally {
      setLoading(false);
    }
  };

  // Cargar tipos de carnet para un país específico
  const fetchTiposDeCarnet = async () => {
    if (!selectedPais) return;
    setLoading(true);
    try {
      const response = await apiClient(`${getTiposDeCarnetUrl}/${selectedPais}`);
      setTiposDeCarnet(response.data);
    } catch (error) {
      console.error('Error al cargar los tipos de carnet:', error);
    } finally {
      setLoading(false);
    }
  };

  // Cargar temas para un tipo de carnet específico
  const fetchTemas = async () => {
    if (!selectedCarnet) return;
    setLoading(true);
    try {
      const response = await apiClient(`${getTemasUrl}?paisId=${selectedPais}&carnetId=${selectedCarnet}`);
      setTemas(response.data);
    } catch (error) {
      console.error('Error al cargar los temas:', error);
    } finally {
      setLoading(false);
    }
  };

  // Cargar preguntas para un tema específico con paginación
  const fetchPreguntas = async (cursor = null, append = false) => {
    if (!selectedTemario) return;
    setLoading(true);
    try {
      const query = new URLSearchParams({
        paisId: selectedPais,
        carnetId: selectedCarnet,
        temarioId: selectedTemario,
        limit: limit.toString(),
      });

      if (cursor) {
        query.append('lastVisibleId', cursor);
      }

      const response = await apiClient(`${getPreguntasUrl}?${query.toString()}`);
      const data = response.data;

      setPreguntas((prev) => (append ? [...prev, ...data.questions] : data.questions));
      setLastVisibleId(data.lastVisibleId);
      setHasNextPage(!!data.lastVisibleId); // Si lastVisibleId es nulo, no hay más páginas
    } catch (error) {
      console.error('Error al cargar las preguntas:', error);
    } finally {
      setLoading(false);
    }
  };

  // Añadir una nueva pregunta
  const handleAddPregunta = async (e) => {
    e.preventDefault();

    if (
      !questionText ||
      !selectedPais ||
      !selectedCarnet ||
      !selectedTemario ||
      answers.some((answer) => !answer.answerText)
    ) {
      alert('Por favor completa todos los campos.');
      return;
    }

    setError('');
    setLoading(true);

    const questionData = {
      questionText,
      answers,
      imageUrl,
      country: selectedPais,
      permission: selectedCarnet,
      temary: selectedTemario,
    };

    try {
      await apiClient(`${addPreguntaUrl}/${selectedPais}/${selectedCarnet}/${selectedTemario}/preguntas`, {
        method: 'POST',
        data: questionData,
      });

      setQuestionText('');
      setAnswers([
        { answerText: '', correct: false },
        { answerText: '', correct: false },
        { answerText: '', correct: false },
      ]);
      setImageUrl('');
      setSuccess('Pregunta añadida correctamente.');
      fetchPreguntas(); // Recargar las preguntas
    } catch (error) {
      console.error('Error al añadir la pregunta:', error);
      setError('Error al añadir la pregunta.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaises();
  }, []);

  useEffect(() => {
    if (selectedPais) {
      fetchTiposDeCarnet();
    }
  }, [selectedPais]);

  useEffect(() => {
    if (selectedCarnet) {
      fetchTemas();
    }
  }, [selectedCarnet]);

  useEffect(() => {
    if (selectedTemario) {
      fetchPreguntas();
    }
  }, [selectedTemario]);

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index].answerText = value;
    setAnswers(updatedAnswers);
  };

  const handleCorrectChange = (index) => {
    const updatedAnswers = answers.map((answer, i) => ({
      ...answer,
      correct: i === index,
    }));
    setAnswers(updatedAnswers);
  };

  return (
    <div className="p-6 w-full">
      <h2 className="text-2xl font-semibold text-center mb-4">Gestión de Preguntas y Temas</h2>

      {/* Seleccionar País */}
      <div className="mb-4">
        <label className="block text-gray-700 font-medium">Seleccionar País:</label>
        <select
          value={selectedPais}
          onChange={(e) => setSelectedPais(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-2 mt-1"
        >
          <option value="">Seleccione un país</option>
          {paises.map((pais) => (
            <option key={pais.id} value={pais.id}>
              {pais.nombre}
            </option>
          ))}
        </select>
      </div>

      {/* Seleccionar Tipo de Carnet */}
      <div className="mb-4">
        <label className="block text-gray-700 font-medium">Seleccionar Tipo de Carnet:</label>
        <select
          value={selectedCarnet}
          onChange={(e) => setSelectedCarnet(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-2 mt-1"
          disabled={!selectedPais}
        >
          <option value="">Seleccione un tipo de carnet</option>
          {tiposDeCarnet.map((carnet) => (
            <option key={carnet.id} value={carnet.id}>
              {carnet.nombre}
            </option>
          ))}
        </select>
      </div>

      {/* Seleccionar Temario */}
      <div className="mb-4">
        <label className="block text-gray-700 font-medium">Seleccionar Temario:</label>
        <select
          value={selectedTemario}
          onChange={(e) => setSelectedTemario(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-2 mt-1"
          disabled={!selectedCarnet}
        >
          <option value="">Seleccione un temario</option>
          {temas.map((tema) => (
            <option key={tema.id} value={tema.id}>
              {tema.nombre}
            </option>
          ))}
        </select>
      </div>

      {/* Formulario para Añadir Pregunta */}
      <div className="p-4 border rounded-md shadow-md mb-6">
        <h3 className="text-xl font-semibold mb-2">Añadir una Nueva Pregunta</h3>
        {error && <p className="text-red-500 mb-2">{error}</p>}
        {success && <p className="text-green-500 mb-2">{success}</p>}

        <form onSubmit={handleAddPregunta}>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Texto de la Pregunta:</label>
            <input
              type="text"
              value={questionText}
              onChange={(e) => setQuestionText(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 mt-1"
              placeholder="Escribe la pregunta"
            />
          </div>

          {answers.map((answer, index) => (
            <div key={index} className="mb-4">
              <label className="block text-gray-700 font-medium">Respuesta {index + 1}:</label>
              <input
                type="text"
                value={answer.answerText}
                onChange={(e) => handleAnswerChange(index, e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2"
                placeholder={`Respuesta ${index + 1}`}
              />
              <div className="mt-2">
                <label>
                  <input
                    type="radio"
                    name="correctAnswer"
                    checked={answer.correct}
                    onChange={() => handleCorrectChange(index)}
                    className="mr-2"
                  />
                  Marcar como correcta
                </label>
              </div>
            </div>
          ))}

          <div className="mb-4">
            <label className="block text-gray-700 font-medium">URL de la imagen:</label>
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 mt-1"
              placeholder="Ingresa la URL de la imagen"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
            disabled={loading}
          >
            {loading ? 'Guardando...' : 'Guardar Pregunta'}
          </button>
        </form>
      </div>

      {/* Tabla de Preguntas con Paginación */}
      <div className="overflow-x-auto">
        <h3 className="text-xl font-semibold text-center mb-4">Preguntas del Temario Seleccionado</h3>
        {loading ? (
          <p className="text-center text-gray-500">Cargando preguntas...</p>
        ) : preguntas.length === 0 ? (
          <p className="text-center text-gray-500">No se encontraron preguntas.</p>
        ) : (
          <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-md">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Texto de la Pregunta</th>
                <th className="py-2 px-4 text-center font-semibold text-gray-700">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {preguntas.map((pregunta) => (
                <tr key={pregunta.id} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-4">{pregunta.questionText}</td>
                  <td className="py-2 px-4 text-center">
                    <button className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 mr-2">
                      Editar
                    </button>
                    <button className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600">
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Paginación */}
      {hasNextPage && (
        <div className="flex justify-center mt-6">
          <button
            onClick={() => fetchPreguntas(lastVisibleId, true)}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Cargar más
          </button>
        </div>
      )}
    </div>
  );
};

export default GestorTemasPreguntas;
