import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseConfig";
import LogoutButton from "./LogoutButton"; // Botón de Logout
import UserTable from "./UserTable";
import Paises from "./Paises";
import Temario from "./Temas";
import TestsTable from "./TestsTable";
import MetricsTable from "./MetricsTable";
import QuestionForm from "./questions/QuestionsForm";
import QuestionsTable from "./questions/QuestionsTable";
import EditQuestion from "./questions/EditQuestion";
import UploadQuestions from "./questions/UploadQuestions";
import Login from "./Login";
import TiposDeCarnetTable from "./TiposDeCarnet";
import Preguntas from "./Preguntas";

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const ProtectedRoute = ({ children }) => {
    if (loading) {
      return <div>Loading...</div>;
    }
    return user ? children : <Navigate to="/login" />;
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-100 p-6">
        <header className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-blue-600">CarnetFacil.es</h1>
          {user && <LogoutButton />} {/* Botón de Logout para usuarios autenticados */}
        </header>

        {/* Botones de Navegación */}
        {user && (
          <nav className="flex justify-center space-x-4 mb-6">
            <Link
              to="/paises"
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
            >
              Paises
            </Link>
            <Link
              to="/permisos"
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
            >
              Tipos de Permisos
            </Link>
            <Link
              to="/temario"
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
            >
              Temario
            </Link>
            {/* <Link
              to="/usuarios"
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
            >
              Usuarios
            </Link>
            <Link
              to="/tests"
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
            >
              Preguntas reportadas
            </Link> */}
            {/* <Link
              to="/metrics"
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
            >
              Métricas
            </Link> */}
            <Link
              to="/questions"
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
            >
              Preguntas
            </Link>
            {/* <Link
              to="/questions/add"
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
            >
              Añadir Preguntas
            </Link> */}
            <Link
              to="/questions/import"
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
            >
              Importar Preguntas
            </Link>
          </nav>
        )}

        {/* Rutas */}
        <div className="bg-white p-6 rounded-md shadow-md">
          <Routes>
            <Route path="/login" element={user ? <Navigate to="/usuarios" /> : <Login />} />
            <Route
              path="/paises"
              element={
                <ProtectedRoute>
                  <Paises />
                </ProtectedRoute>
              }
            />
            <Route
              path="/permisos"
              element={
                <ProtectedRoute>
                  <TiposDeCarnetTable />
                </ProtectedRoute>
              }
            />
            <Route
              path="/temario"
              element={
                <ProtectedRoute>
                  <Temario />
                </ProtectedRoute>
              }
            />
            <Route
              path="/usuarios"
              element={
                <ProtectedRoute>
                  <UserTable />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tests"
              element={
                <ProtectedRoute>
                  <TestsTable />
                </ProtectedRoute>
              }
            />
            <Route
              path="/metrics"
              element={
                <ProtectedRoute>
                  <MetricsTable />
                </ProtectedRoute>
              }
            />
            <Route
              path="/questions"
              element={
                <ProtectedRoute>
                  <Preguntas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/questions/add"
              element={
                <ProtectedRoute>
                  <QuestionForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/questions/edit/:id"
              element={
                <ProtectedRoute>
                  <EditQuestion />
                </ProtectedRoute>
              }
            />
            <Route
              path="/questions/import"
              element={
                <ProtectedRoute>
                  <UploadQuestions />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
