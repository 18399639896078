import React, { useEffect, useState } from 'react';
import apiClient from './apiClient'; // Importar apiClient para llamadas a la API

const TemasTable = () => {
    const [paises, setPaises] = useState([]);
    const [tiposDeCarnet, setTiposDeCarnet] = useState([]);
    const [temas, setTemas] = useState([]);
    const [selectedPais, setSelectedPais] = useState('');
    const [selectedCarnet, setSelectedCarnet] = useState('');
    const [nuevoTema, setNuevoTema] = useState({
        nombre: '',
        descripcion: '',
        numero: '',
        icono: '',
        visible: false, // Nuevo campo para 'visible'
    });
    const [temaEnEdicion, setTemaEnEdicion] = useState(null); // Almacena el tema que se está editando, si corresponde
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // URLs de las API
    const getPaisesUrl = process.env.REACT_APP_GET_PAISES_URL;
    const getTiposDeCarnetUrl = process.env.REACT_APP_GET_TIPOS_CARNET_URL;
    const getTemasUrl = process.env.REACT_APP_GET_TEMAS_URL;
    const addTemasUrl = process.env.REACT_APP_CREATE_TEMA_URL;
    const deleteTemasUrl = process.env.REACT_APP_DELETE_TEMA_URL;
    const editTemasUrl = process.env.REACT_APP_EDIT_TEMA_URL;

    // Cargar países desde la API
    const fetchPaises = async () => {
        setLoading(true);
        try {
            const response = await apiClient(getPaisesUrl);
            setPaises(response.data);
        } catch (error) {
            console.error('Error al cargar los países:', error);
        } finally {
            setLoading(false);
        }
    };

    // Cargar tipos de carnet para un país específico
    const fetchTiposDeCarnet = async () => {
        if (!selectedPais) return;
        setLoading(true);
        try {
            const response = await apiClient(`${getTiposDeCarnetUrl}/${selectedPais}`);
            setTiposDeCarnet(response.data);
        } catch (error) {
            console.error('Error al cargar los tipos de carnet:', error);
        } finally {
            setLoading(false);
        }
    };

    // Cargar temas para un tipo de carnet específico
    const fetchTemas = async () => {
        if (!selectedCarnet) return;
        setLoading(true);
        try {
            const response = await apiClient(`${getTemasUrl}?paisId=${selectedPais}&carnetId=${selectedCarnet}`);
            // Ordenar los temas por el campo 'numero' en orden ascendente
            const temasOrdenados = response.data.sort((a, b) => a.numero - b.numero);
            setTemas(temasOrdenados);
        } catch (error) {
            console.error('Error al cargar los temas:', error);
        } finally {
            setLoading(false);
        }
    };

    // Añadir o editar un tema
    const handleSaveTema = async () => {
        // Validar que todos los campos son obligatorios
        if (!nuevoTema.nombre || !nuevoTema.descripcion || !nuevoTema.numero || !nuevoTema.icono) {
            setError('Todos los campos son obligatorios.');
            return;
        }

        setError('');
        setSuccess('');

        if (temaEnEdicion) {
            // Editar tema existente
            try {
                await apiClient(`${editTemasUrl}?paisId=${selectedPais}&carnetId=${selectedCarnet}&temaId=${temaEnEdicion.id}`, {
                    method: 'PUT',
                    data: nuevoTema,
                });
                setTemas(
                    temas.map((tema) =>
                        tema.id === temaEnEdicion.id ? { ...nuevoTema, id: temaEnEdicion.id } : tema
                    )
                );
                setSuccess('Tema editado correctamente.');
                setTemaEnEdicion(null);
                setNuevoTema({ nombre: '', descripcion: '', numero: '', icono: '', visible: false });
            } catch (error) {
                console.error('Error al editar el tema:', error);
                setError('Error al editar el tema.');
            }
        } else {
            // Añadir nuevo tema
            try {
                await apiClient(`${addTemasUrl}?paisId=${selectedPais}&carnetId=${selectedCarnet}`, {
                    method: 'POST',
                    data: nuevoTema,
                });
                setTemas([...temas, { ...nuevoTema, id: Math.random().toString(36).substr(2, 9) }]);
                setNuevoTema({ nombre: '', descripcion: '', numero: '', icono: '', visible: false });
                setSuccess('Tema añadido correctamente.');
            } catch (error) {
                console.error('Error al añadir el tema:', error);
                setError('Error al añadir el tema.');
            }
        }
    };

    // Eliminar un tema
    const handleDeleteTema = async (temaId) => {
        try {
            await apiClient(`${deleteTemasUrl}?paisId=${selectedPais}&carnetId=${selectedCarnet}&temaId=${temaId}`, {
                method: 'DELETE',
            });
            setTemas(temas.filter((tema) => tema.id !== temaId));
            setSuccess('Tema eliminado correctamente.');
        } catch (error) {
            console.error('Error al eliminar el tema:', error);
            setError('Error al eliminar el tema.');
        }
    };

    // Preparar un tema para edición
    const handleEditTema = (tema) => {
        setTemaEnEdicion(tema);
        setNuevoTema({ nombre: tema.nombre, descripcion: tema.descripcion, numero: tema.numero, icono: tema.icono });
    };

    useEffect(() => {
        fetchPaises();
    }, []);

    useEffect(() => {
        if (selectedPais) {
            fetchTiposDeCarnet();
        }
    }, [selectedPais]);

    useEffect(() => {
        if (selectedCarnet) {
            fetchTemas();
        }
    }, [selectedCarnet]);

    return (
        <div className="p-6 max-w-4xl mx-auto">
            <h2 className="text-2xl font-semibold text-center mb-4">Gestión de Temas de Tipos de Carnet</h2>

            {/* Seleccionar País */}
            <div className="mb-4">
                <label className="block text-gray-700 font-medium">Seleccionar País:</label>
                <select
                    value={selectedPais}
                    onChange={(e) => {
                        setSelectedPais(e.target.value);
                        setSelectedCarnet('');
                        setTemas([]);
                    }}
                    className="w-full border border-gray-300 rounded-md p-2 mt-1"
                >
                    <option value="">Seleccione un país</option>
                    {paises.map((pais) => (
                        <option key={pais.id} value={pais.id}>
                            {pais.nombre}
                        </option>
                    ))}
                </select>
            </div>

            {/* Seleccionar Tipo de Carnet */}
            <div className="mb-4">
                <label className="block text-gray-700 font-medium">Seleccionar Tipo de Carnet:</label>
                <select
                    value={selectedCarnet}
                    onChange={(e) => setSelectedCarnet(e.target.value)}
                    className="w-full border border-gray-300 rounded-md p-2 mt-1"
                >
                    <option value="">Seleccione un tipo de carnet</option>
                    {tiposDeCarnet.map((carnet) => (
                        <option key={carnet.id} value={carnet.id}>
                            {carnet.nombre}
                        </option>
                    ))}
                </select>
            </div>

            {/* Formulario para añadir o editar un tema */}
            <div className="p-4 border rounded-md shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-2">
                    {temaEnEdicion ? 'Editar Tema' : 'Añadir un Nuevo Tema'}
                </h3>
                {error && <p className="text-red-500 mb-2">{error}</p>}
                {success && <p className="text-green-500 mb-2">{success}</p>}
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Número del Tema:</label>
                    <input
                        type="number"
                        value={nuevoTema.numero}
                        onChange={(e) => setNuevoTema({ ...nuevoTema, numero: parseInt(e.target.value, 10) })}
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Ejemplo: 1"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Nombre del Tema:</label>
                    <input
                        type="text"
                        value={nuevoTema.nombre}
                        onChange={(e) => setNuevoTema({ ...nuevoTema, nombre: e.target.value })}
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Ejemplo: Reglas de tráfico"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Descripción:</label>
                    <textarea
                        value={nuevoTema.descripcion}
                        onChange={(e) => setNuevoTema({ ...nuevoTema, descripcion: e.target.value })}
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Ejemplo: Reglas generales y específicas"
                    ></textarea>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Icono:</label>
                    <input
                        type="text"
                        value={nuevoTema.icono}
                        onChange={(e) => setNuevoTema({ ...nuevoTema, icono: e.target.value })}
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Ejemplo: URL del icono o nombre del icono"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Visible:</label>
                    <input
                        type="checkbox"
                        checked={nuevoTema.visible}
                        onChange={(e) => setNuevoTema({ ...nuevoTema, visible: e.target.checked })}
                        className="mt-1"
                    />
                </div>
                <button
                    onClick={handleSaveTema}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                    {temaEnEdicion ? 'Guardar Cambios' : 'Añadir Tema'}
                </button>
                {temaEnEdicion && (
                    <button
                        onClick={() => {
                            setTemaEnEdicion(null);
                            setNuevoTema({ nombre: '', descripcion: '', numero: '', icono: '' });
                        }}
                        className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                    >
                        Cancelar
                    </button>
                )}
            </div>

            {/* Tabla de temas */}
            <div className="overflow-x-auto">
                {loading ? (
                    <p className="text-center text-gray-500">Cargando datos...</p>
                ) : temas.length === 0 ? (
                    <p className="text-center text-gray-500">No se encontraron temas.</p>
                ) : (
                    <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-md">
                        <thead>
                            <tr className="bg-gray-100 border-b">
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Número</th>
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Nombre del Tema</th>
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Descripción</th>
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Icono</th>
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Visible</th> {/* Nueva columna */}
                                <th className="py-2 px-4 text-center font-semibold text-gray-700">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {temas.map((tema) => (
                                <tr key={tema.id} className="border-b hover:bg-gray-50">
                                    <td className="py-2 px-4">{tema.numero}</td>
                                    <td className="py-2 px-4">{tema.nombre}</td>
                                    <td className="py-2 px-4">{tema.descripcion}</td>
                                    <td className="py-2 px-4">{tema.icono}</td>
                                    <td className="py-2 px-4">{tema.visible ? 'Sí' : 'No'}</td> {/* Mostrar 'Sí' o 'No' */}
                                    <td className="py-2 px-4 text-center">
                                        <button
                                            onClick={() => handleEditTema(tema)}
                                            className="bg-yellow-500 text-white px-3 py-1 rounded-md hover:bg-yellow-600 mr-2"
                                        >
                                            Editar
                                        </button>
                                        <button
                                            onClick={() => handleDeleteTema(tema.id)}
                                            className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
                                        >
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                )}
            </div>
        </div>
    );
};

export default TemasTable;
