import React, { useEffect, useState } from 'react';
import apiClient from './apiClient';

const TiposDeCarnetTable = () => {
    const [paises, setPaises] = useState([]);
    const [selectedPais, setSelectedPais] = useState('');
    const [tiposDeCarnet, setTiposDeCarnet] = useState([]);
    const [nuevoCarnet, setNuevoCarnet] = useState({ nombre: '', descripcion: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const getPaisesUrl = process.env.REACT_APP_GET_PAISES_URL;
    const getTiposDeCarnetUrl = process.env.REACT_APP_GET_TIPOS_CARNET_URL;
    const addTiposDeCarnetUrl = process.env.REACT_APP_CREATE_TIPO_CARNET_URL;

    // Cargar países desde la API
    const fetchPaises = async () => {
        setLoading(true);
        try {
            const response = await apiClient(getPaisesUrl);
            setPaises(response.data); // Asegúrate de que `response.data` sea un array
        } catch (error) {
            console.error('Error al cargar los países:', error);
        } finally {
            setLoading(false);
        }
    };

    // Cargar tipos de carnet para el país seleccionado
    const fetchTiposDeCarnet = async () => {
        if (!selectedPais) {
            setTiposDeCarnet([]);
            return;
        }

        setLoading(true);
        try {
            const response = await apiClient(`${getTiposDeCarnetUrl}/${selectedPais}`);
            setTiposDeCarnet(response.data); // Asegúrate de que `response.data` sea un array
        } catch (error) {
            console.error('Error al cargar los tipos de carnet:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPaises();
    }, []);

    useEffect(() => {
        fetchTiposDeCarnet();
    }, [selectedPais]);

    // Añadir un nuevo tipo de carnet
    const handleAddCarnet = async () => {
        if (!selectedPais || !nuevoCarnet.nombre || !nuevoCarnet.descripcion) {
            setError('Todos los campos son obligatorios.');
            return;
        }

        setError('');
        setSuccess('');

        try {
            await apiClient(`${addTiposDeCarnetUrl}/${selectedPais}`, {
                method: 'POST',
                data: nuevoCarnet,
            });

            // Actualizar la lista de tipos de carnet localmente
            setTiposDeCarnet([...tiposDeCarnet, nuevoCarnet]);
            setNuevoCarnet({ nombre: '', descripcion: '' });
            setSuccess('Tipo de carnet añadido correctamente.');
        } catch (error) {
            console.error('Error al añadir el tipo de carnet:', error);
        }
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            <h2 className="text-2xl font-semibold text-center mb-4">Gestión de Tipos de Carnet</h2>

            {/* Seleccionar País */}
            <div className="mb-4">
                <label className="block text-gray-700 font-medium">Seleccionar País:</label>
                <select
                    value={selectedPais}
                    onChange={(e) => setSelectedPais(e.target.value)}
                    className="w-full border border-gray-300 rounded-md p-2 mt-1"
                >
                    <option value="">Seleccione un país</option>
                    {paises.map((pais) => (
                        <option key={pais.id} value={pais.id}>
                            {pais.nombre}
                        </option>
                    ))}
                </select>
            </div>

            {/* Formulario para añadir tipo de carnet */}
            <div className="p-4 border rounded-md shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-2">Añadir un Nuevo Tipo de Carnet</h3>
                {error && <p className="text-red-500 mb-2">{error}</p>}
                {success && <p className="text-green-500 mb-2">{success}</p>}
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Nombre del Carnet:</label>
                    <input
                        type="text"
                        value={nuevoCarnet.nombre}
                        onChange={(e) =>
                            setNuevoCarnet({ ...nuevoCarnet, nombre: e.target.value })
                        }
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Ejemplo: Conducir automóviles"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Descripción:</label>
                    <textarea
                        value={nuevoCarnet.descripcion}
                        onChange={(e) =>
                            setNuevoCarnet({ ...nuevoCarnet, descripcion: e.target.value })
                        }
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Ejemplo: Permiso B"
                    ></textarea>
                </div>
                <button
                    onClick={handleAddCarnet}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                    Añadir Tipo de Carnet
                </button>
            </div>

            {/* Tabla de tipos de carnet */}
            <div className="overflow-x-auto">
                {loading ? (
                    <p className="text-center text-gray-500">Cargando datos...</p>
                ) : tiposDeCarnet.length === 0 ? (
                    <p className="text-center text-gray-500">No se encontraron tipos de carnet.</p>
                ) : (
                    <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-md">
                        <thead>
                            <tr className="bg-gray-100 border-b">
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Nombre</th>
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Descripción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tiposDeCarnet.map((carnet, index) => (
                                <tr key={index} className="border-b hover:bg-gray-50">
                                    <td className="py-2 px-4">{carnet.nombre}</td>
                                    <td className="py-2 px-4">{carnet.descripcion}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default TiposDeCarnetTable;
