import React, { useEffect, useState } from 'react';
import apiClient from './apiClient';

const PaisesTable = () => {
    const [paises, setPaises] = useState([]);
    const [nuevoPais, setNuevoPais] = useState({ nombre: '', codigo: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const getPaisesUrl = process.env.REACT_APP_GET_PAISES_URL;
    const addPaisUrl = process.env.REACT_APP_CREATE_PAIS_URL;

    // Cargar países desde la API
    const fetchPaises = async () => {
        setLoading(true);
        try {
            const response = await apiClient(getPaisesUrl);
            setPaises(response.data); // Asegúrate de que `response.data` sea un array
        } catch (error) {
            console.error('Error al cargar los países:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPaises(); // Cargar los países al montar el componente
    }, []);

    // Añadir un nuevo país
    const handleAddPais = async () => {
        if (!nuevoPais.nombre || !nuevoPais.codigo) {
            setError('Todos los campos son obligatorios.');
            return;
        }
        setError('');

        try {
            // Llamada a la API para agregar el país
            await apiClient(addPaisUrl, {
                method: 'POST',
                data: nuevoPais,
            });

            // Actualizar la lista de países localmente
            setPaises([...paises, nuevoPais]);
            setNuevoPais({ nombre: '', codigo: '' }); // Resetear formulario
        } catch (error) {
            console.error('Error al añadir el país:', error);
        }
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            <h2 className="text-2xl font-semibold text-center mb-4">Gestión de Países</h2>

            {/* Formulario para añadir un nuevo país */}
            <div className="p-4 border rounded-md shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-2">Añadir un Nuevo País</h3>
                {error && <p className="text-red-500 mb-2">{error}</p>}
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Nombre del País:</label>
                    <input
                        type="text"
                        value={nuevoPais.nombre}
                        onChange={(e) => setNuevoPais({ ...nuevoPais, nombre: e.target.value })}
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Ejemplo: España"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Código del País:</label>
                    <input
                        type="text"
                        value={nuevoPais.codigo}
                        onChange={(e) => setNuevoPais({ ...nuevoPais, codigo: e.target.value })}
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Ejemplo: ES"
                    />
                </div>
                <button
                    onClick={handleAddPais}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                    Añadir País
                </button>
            </div>

            {/* Tabla de países */}
            <div className="overflow-x-auto">
                {loading ? (
                    <p className="text-center text-gray-500">Cargando países...</p>
                ) : paises.length === 0 ? (
                    <p className="text-center text-gray-500">No se encontraron países.</p>
                ) : (
                    <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-md">
                        <thead>
                            <tr className="bg-gray-100 border-b">
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Nombre del País</th>
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Código</th>
                                <th className="py-2 px-4 text-center font-semibold text-gray-700">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paises.map((pais, index) => (
                                <tr key={index} className="border-b hover:bg-gray-50">
                                    <td className="py-2 px-4">{pais.nombre}</td>
                                    <td className="py-2 px-4">{pais.codigo}</td>
                                    <td className="py-2 px-4 text-center">
                                        <button
                                            onClick={() => alert(`Editando país ${pais.nombre}`)}
                                            className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 mr-2"
                                        >
                                            Editar
                                        </button>
                                        <button
                                            onClick={() => alert(`Eliminando país ${pais.nombre}`)}
                                            className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
                                        >
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default PaisesTable;
